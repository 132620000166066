
import ApiService from "@/core/services/ApiService"
import { defineComponent, onMounted, ref, watch } from "vue"
import { Edit } from "@element-plus/icons-vue"
import { useRoute, useRouter } from "vue-router"
import { DateFormatTypes, dateForm } from "@/core/helpers/dateformat"
import SemesterComponent from "@/components/education/definitions/semester/semester.vue"
import { Semester } from "@/core/models/Semester"
import { SelectModel } from "@/core/models/SelectModel"
import swalConfirm from "@/core/helpers/swal-confirm"

export default defineComponent({
  name: "semester-list",
  components: { SemesterComponent },
  props: ["add"],
  setup(props) {
    const route = useRoute()
    const router = useRouter()

    const isTableDataLoading = ref(false)
    const isBusinessSelectLoading = ref(false)

    const newSemester = ref(false)

    const businessList = ref<SelectModel[]>([])
    const semesterList = ref<Semester[]>([])
    const activeBusinessId = ref<string>()

    const activeSemesterId = ref<string | undefined>()

    watch(
      () => route.params.semester_id,
      newValue => {
        activeSemesterId.value = newValue as string
      }
    )

    watch(
      () => props.add,
      newValue => {
        newSemester.value = true == newValue
      }
    )

    onMounted(async () => {
      activeSemesterId.value = route.params.semester_id as string
      newSemester.value = props.add
      await getBusinessList()
    })

    async function getBusinessList() {
      isBusinessSelectLoading.value = true
      const { data } = await ApiService.get("business")
      businessList.value = data.filter(x => x.isActive).map(x => ({ name: x.title, id: x.id }))
      isBusinessSelectLoading.value = false
      if (businessList.value.length == 1) {
        activeBusinessId.value = businessList.value[0].id
        getSemesterList(activeBusinessId.value)
      }
    }

    async function getSemesterList(businessId) {
      if (!businessId) return
      isTableDataLoading.value = true
      const { data } = await ApiService.get("semester/business/" + businessId)
      semesterList.value = data.sort((a, b) => (a.startDate > b.startDate ? 1 : -1))
      isTableDataLoading.value = false
    }

    async function removeSemester(semesterId) {
      const confirmResult = await swalConfirm()
      if (confirmResult.isConfirmed) {
        await ApiService.get("semester/remove/" + semesterId)
        await getSemesterList(activeBusinessId.value)
      }
    }

    function handleSemesterDrawerClose() {
      router.push({ name: "semester-list" })
    }

    return {
      dateForm,
      DateFormatTypes,
      isTableDataLoading,
      semesterList,
      businessList,
      activeBusinessId,
      Edit,
      removeSemester,
      getSemesterList,
      activeSemesterId,
      newSemester,
      handleSemesterDrawerClose,
      isBusinessSelectLoading,
    }
  },
})
