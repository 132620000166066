
import { getRule, RuleTypes } from "@/core/helpers/rules"
import { SelectModel } from "@/core/models/SelectModel"
import { Semester } from "@/core/models/Semester"
import ApiService from "@/core/services/ApiService"
import { InternalRuleItem, Value } from "async-validator"

import { ElForm } from "element-plus"
import { computed, defineComponent, ref, onMounted, toRefs, watch } from "vue"
import { useRouter } from "vue-router"

export default defineComponent({
  name: "semester-component",
  props: ["add", "id", "closed"],
  emits: ["closed", "getlist"],
  setup(props, { emit }) {
    const router = useRouter()
    const { add, id } = toRefs(props)

    const semesterData = ref<Semester>({} as Semester)

    type FormInstance = InstanceType<typeof ElForm>

    const ruleFormRef = ref<FormInstance>()

    const businessList = ref<SelectModel[]>([])

    const validateStartDate = (
      rule: InternalRuleItem,
      value: Value,
      callback: (error?: string | Error) => void
    ) => {
      let end = semesterData.value.endDate as Date
      if (value > end) {
        callback(new Error("Başlangıç zamanı bitiş zamanından önce olmalıdır"))
      } else {
        callback()
      }
    }
    const validateEndDate = (
      rule: InternalRuleItem,
      value: Value,
      callback: (error?: string | Error) => void
    ) => {
      let start = semesterData.value.startDate as Date
      ruleFormRef.value?.validateField("startDate", () => null)
      if (start > value) {
        callback(new Error("Bitiş zamanı başlangıç zamanından sonra olmalıdır"))
      } else {
        callback()
      }
    }

    const rules = ref({
      businessId: getRule(RuleTypes.SELECT, "İşletme"),
      startDate: getRule(RuleTypes.DATE, "Başlangıç", [
        {
          validator: validateStartDate,
          trigger: "change",
        },
      ]),
      endDate: getRule(RuleTypes.DATE, "Bitiş", [
        {
          validator: validateEndDate,
          trigger: "change",
        },
      ]),
      name: getRule(RuleTypes.TEXT50, "Ad"),
      code: getRule(RuleTypes.TEXT50, "Kod"),
    })

    const getSemester = async id => {
      const { data } = await ApiService.get("semester/" + id)
      semesterData.value = data
    }

    watch(
      () => props.id,
      newValue => {
        if (newValue) {
          ruleFormRef.value?.resetFields()
          getSemester(newValue)
        }
      }
    )

    const showDrawer = computed(() => props.id != undefined || props.add == true)

    watch(
      () => props.add,
      newValue => {
        if (newValue) {
          ruleFormRef.value?.resetFields()
          semesterData.value = {} as Semester
          initBusinessList()
        }
      }
    )

    async function addSemester() {
      const data = { ...semesterData.value }
      await ApiService.post("semester/add", data)
      emit("getlist")
      router.push({ name: "semester-list" })
    }

    async function updateSemester() {
      const data = { ...semesterData.value, id: props.id }
      await ApiService.post("semester/edit", data)
      emit("getlist")
      router.push({ name: "semester-list" })
    }

    function addOrUpdate(formEl) {
      if (!formEl) return
      formEl.validate(valid => {
        if (valid) {
          if (props.add) {
            addSemester()
          } else {
            updateSemester()
          }
        }
      })
    }

    onMounted(async () => {
      await getBusinessList()
    })

    async function getBusinessList() {
      const { data } = await ApiService.get("business")
      businessList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
      initBusinessList()
    }

    const initBusinessList = () => {
      if (businessList.value.length == 1) {
        const onlyBusinessId = businessList.value[0].id
        semesterData.value.businessId = onlyBusinessId
      }
    }

    return {
      showDrawer,
      newSemester: add,
      activeSemesterId: id,
      handleClose: props.closed,
      semesterData,
      addOrUpdate,
      ruleFormRef,
      businessList,
      rules,
    }
  },
})
